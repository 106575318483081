















































































import { computed, defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { SplashUserType, TableUserData, UserList } from "@/types/typeDefinitions";
import { useUserAPI } from "@/api_connectors/user";
import EditUser from "@/components/Modal/EditUser.vue";
import { translate } from "@/plugins/multi-language";
import SavedToast from "@/components/Toast/SavedToast.vue";
import DeleteToast from "@/components/Toast/DeleteToast.vue";
import CheckDelete from "@/components/Modal/CheckDelete.vue";
import FailedRequest from "@/components/Toast/FailedRequest.vue";
import SuccessRequest from "@/components/Toast/SuccessRequest.vue";
import SaveFailedToast from "@/components/Toast/SaveFailedToast.vue";
import { useToast } from "@/composables/useToast";
import usePagination from "@/composables/usePagination";

export default defineComponent({
    name: "UserManagement",
    components: { SaveFailedToast, SuccessRequest, FailedRequest, CheckDelete, DeleteToast, SavedToast, EditUser },
    setup() {
        const { deleteUser } = useUserAPI()
        const { showToast, getTargetName } = useToast()
        const { getPaginatedList } = usePagination()

        interface UserTableItem {
            email: string;
            newsletter: boolean;
            type: string;
            name: string;
            editID: string | undefined;
            deleteID: string | undefined;
        }

        let userList = reactive<TableUserData[]>([])
        const tableItems = reactive<UserTableItem[]>([]);
        const filter = ref('')
        const sortBy = ref('email')
        const sortDesc = ref(false)
        const fields = computed(() => [
            { key: 'email', sortable: true, label: translate('login.email'), thClass: 'col-4' },
            { key: 'name', sortable: true, label: translate('login.name'), thClass: 'col-3' },
            { key: 'newsletter', sortable: true, label: translate('login.newsletter'), thClass: 'col-1' },
            { key: 'type', sortable: true, label: translate('login.type'), thClass: 'col-2' },
            { key: 'editID', sortable: false, label: '', thClass: 'col-1' },
            { key: 'deleteID', sortable: false, label: '', thClass: 'col-1' }
        ])
        const currentPage = ref(1);
        const perPage = ref(10);
        const rows = ref(0);
        const pageOptions = [
            { text: '10', value: 10 },
            { text: '25', value: 25 },
            { text: '50', value: 50 },
        ]

        const selectedUserID = ref('')
        const target = ref('')
        const requestTarget = ref('saveUser')

        const update = async () => {
            await updateUserPagination()
        }

        const openNewUserDialog = (openModal: Function): void => {
            selectedUserID.value = 'new';
            openModal();
        }

        const deleteUserWithID = async (): Promise<void> => {
            // console.log(userId)
            const response = await deleteUser(selectedUserID.value)

            if (response) showToast('delete-toast')
            else {
                changeTargetUser('delete')
                showToast('failed-request')
            }
            await update();
        }

        const editUserWithId = async (userId: string, openModal: Function): Promise<void> => {
            selectedUserID.value = userId;
            // console.log(selectedUserID.value);
            openModal()
        }

        const changeTargetUser = (eventTarget: string) => {
            target.value = eventTarget
            const userTargets = { deleteTarget: 'deleteUser', editTarget: 'saveUser', createTarget: 'createUser' }
            requestTarget.value = getTargetName(eventTarget, userTargets)
        }

        const convertUserList = (list: UserList[]) => {
            tableItems.splice(0)
            for (const user of list) {
                const { email, newsletter, type, firstName, lastName, id } = user

                tableItems.push({
                    email: email,
                    newsletter: newsletter,
                    type: type ? type as SplashUserType : 'none',
                    name: `${firstName} ${lastName}`,
                    editID: id,
                    deleteID: id
                })
            }
        }

        const updateUserPagination = async (page?: number) => {
            if (!page) page = currentPage.value
            const offset = (page - 1) * perPage.value
            const { list, maxCount } =
                await getPaginatedList('/api/admin/userManagement/userList', filter.value, offset, perPage.value)
            convertUserList(list)
            rows.value = maxCount
        }

        onMounted(() => update())

        return {
            userList,
            filter,
            sortBy,
            sortDesc,
            fields,
            selectedUserID,
            currentPage,
            perPage,
            rows,
            pageOptions,
            // items,
            tableItems,
            target,
            requestTarget,
            openNewUserDialog,
            deleteUserWithID,
            editUserWithId,
            update,
            changeTargetUser,
            updateUserPagination
        }
    }
})
