
























































































































import { defineComponent, reactive, ref, watch } from "@vue/composition-api";
import { useUserAPI } from "@/api_connectors/user";
import { User } from "@/types/typeDefinitions";
import LicenseRow from "@/components/LicenseRow.vue";
import { useEmptyObject } from "@/types/newEmptyObject";
import { translate } from "@/plugins/multi-language";
import languageOptions from "@/composables/selectOptions";
import { useToast } from "@/composables/useToast";

export default defineComponent({
    name: "EditUser",
    components: { LicenseRow },
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const { adminGetUserData, setUserDataAdmin, createUser } = useUserAPI()
        const { newUser } = useEmptyObject()
        const { showToast } = useToast()

        const user = reactive<User>(newUser());
        const passwordConfirm = ref('')

        let originalUser = ''

        const typeOptions = [
            { value: 'none', text: translate('types.none') },
            { value: 'teacher', text: translate('types.teacher') },
            { value: 'student', text: translate('types.student') },
            { value: 'regular', text: translate('types.regular') },
            { value: 'school', text: translate('types.school') },
        ]

        const update = async (): Promise<void> => {
            // console.log("Reassign User")
            delete user.pendingEmail;
            if (props.userId === 'new') {
                Object.assign(user, newUser())
            } else {
                const apiUser = await adminGetUserData(props.userId)
                originalUser = JSON.stringify(apiUser)
                // console.log("APIUser", apiUser)
                if (!apiUser) return;
                Object.assign(user, apiUser)
                // console.log("Shown User", user)
            }
        }

        const saveUser = async (closeModal: Function) => {
            // if (user.password) {
            //     if(user.password !== passwordConfirm.value) return;
            // }
            // passwordConfirm.value = ''
            // console.log("User", JSON.stringify(user))
            try {
                await setUserDataAdmin(user)
            } catch (e) {
                context.emit('save-failed', 'save')
                showToast('save-failed-toast')
                return closeModal();
            }
            context.emit('user-data-changed', 'save')
            showToast('saved-toast')
            closeModal()
        }

        const cancelEdit = (closeModal: Function): void => {
            // console.log('cancel Edit User')
            if (props.userId === 'new') {
                Object.assign(user, newUser())
                // passwordConfirm.value = ''
                return closeModal();
            }

            try {
                const tempUser = JSON.parse(originalUser)
                Object.assign(user, tempUser)
                closeModal();
            } catch (e) {
                closeModal();
            }
            // console.log("User", user)
        }

        const createNewUser = async (closeModal: Function) => {
            // console.log('create user')
            if (user.password) {
                if (user.password !== passwordConfirm.value) return;
            }
            // console.log(user)
            const response = await createUser(user)

            if (!response) {
                context.emit('save-failed', 'create')
                return showToast('save-failed-toast')
            }

            Object.assign(user, newUser())
            passwordConfirm.value = ''

            context.emit('user-data-changed', 'create')
            showToast('saved-toast')
            closeModal();
        }

        watch(props, () => {
            // console.log('userid changed')
            if (props.userId === '') return;
            update()
        })

        return {
            user,
            typeOptions,
            passwordConfirm,
            saveUser,
            cancelEdit,
            createNewUser,
            languageOptions
        }
    }
})
