


























import { defineComponent, PropType } from "@vue/composition-api";
import { DateOptions, License } from "@/types/typeDefinitions";

export default defineComponent({
    name: "LicenseRow",
    props: {
        license: {
            type: Object as PropType<License>,
            required: true
        }
    },
    setup() {
        return {
            DateOptions
        }
    }
})
